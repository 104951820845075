import { useListContact } from "~/providers/ListContactProvider";
import { GithubContactProvider } from "~/providers/GithubContactProvider";
import { ReactNode } from "react";
import { UserContact } from "@openqlabs/drm-db";
import { EvaluationProvider } from "~/providers/EvaluationProvider";
import { DepsEvaluationProvider } from "~/providers/DepsEvaluationProvider";
import useComprehensiveListContactInfo from "~/hooks/useComprehensiveListContactInfo";

const TabWrapper = ({
  children,
  type,
}: {
  children: ReactNode | ReactNode[];
  type: "repo" | "user";
}) => {
  const { listContact } = useListContact();
  const contactWithChildren = useComprehensiveListContactInfo(
    type,
    listContact
  );
  const githubEvaluation = contactWithChildren?.githubEvaluation;
  const githubDepsEvaluation = contactWithChildren?.githubDepsEvaluation;
  const dependenciesType =
    type === "repo" ? "repo-dependencies" : "user-dependencies";
  return (
    <GithubContactProvider
      initialContact={listContact.githubContact as unknown as UserContact}
    >
      <EvaluationProvider evaluation={githubEvaluation} type="repo">
        <DepsEvaluationProvider
          depsEvaluation={githubDepsEvaluation}
          type={dependenciesType}
        >
          {children}
        </DepsEvaluationProvider>
      </EvaluationProvider>
    </GithubContactProvider>
  );
};

export default TabWrapper;
