import LoadingBar from "~/loaders/LoadingBar";
import { RouterOutputs } from "~/server/trpc/react";

const ProgressLoadingContactBatchCreations = ({
  contactBatchCreations,
}: {
  contactBatchCreations:
    | RouterOutputs["contactBatchCreation"]["latestContactBatchCreationCountsByTypeAndListId"]
    | undefined;
}) => {
  const waitingUserContacts = contactBatchCreations?.user?.waitingContacts ?? 0;
  const waitingRepoContacts = contactBatchCreations?.repo?.waitingContacts ?? 0;

  const doneUserContactCreations =
    contactBatchCreations?.user?.doneContacts ?? 0;
  const totalUserContactCreations =
    contactBatchCreations?.user?.totalContacts ?? 0;

  const doneRepoContactCreations =
    contactBatchCreations?.repo?.doneContacts ?? 0;
  const totalRepoContactCreations =
    contactBatchCreations?.repo?.totalContacts ?? 0;
  const userProgress =
    (100 * doneUserContactCreations) / totalUserContactCreations;
  const repoProgress =
    (100 * doneRepoContactCreations) / totalRepoContactCreations;

  return (
    <div className="z-40 flex flex-col bg-transparent text-xs text-gray-500">
      {waitingUserContacts > 0 && (
        <div className="py-2">
          <div>
            Creating contacts {doneUserContactCreations} /{" "}
            {totalUserContactCreations} users
          </div>
          <LoadingBar completed={userProgress} />
        </div>
      )}
      {waitingRepoContacts > 0 && (
        <div className="py-2">
          <div>
            Creating contacts {doneRepoContactCreations} /{" "}
            {totalRepoContactCreations} repositories
          </div>
          <LoadingBar completed={repoProgress} />
        </div>
      )}
    </div>
  );
};
export default ProgressLoadingContactBatchCreations;
