import CardMember from "~/components/lists/MainPage/Cards/SharedCard/Member";
import { TeamRenderContact } from "~/providers/ListContactProvider";
import { getUniqueArray } from "@openqlabs/utils";

export default function CardMembers({
  listContact,
}: {
  listContact: NonNullable<TeamRenderContact>;
}) {
  const repositories = getUniqueArray(
    listContact.contributors?.map((author) => {
      return {
        avatarUrl: author.avatarUrl,
        url: `https://github.com/${author.login}`,
        id: author.avatarUrl,
      };
    }) ?? []
  );

  return (
    <div className="flex w-72 items-center justify-center -space-x-3 p-3">
      {repositories[0]?.avatarUrl && (
        <CardMember
          src={repositories[0].url}
          avatarUrl={repositories[0].avatarUrl}
          className="z-10 h-12 w-12"
        />
      )}
      {repositories[1]?.avatarUrl && (
        <CardMember
          src={repositories[1].url}
          avatarUrl={repositories[1].avatarUrl}
          className="z-20 h-14 w-14"
        />
      )}
      {repositories[2]?.avatarUrl && (
        <CardMember
          src={repositories[2].url}
          avatarUrl={repositories[2].avatarUrl}
          className="z-30 h-16 w-16"
        />
      )}
      {repositories[3]?.avatarUrl && (
        <CardMember
          src={repositories[3].url}
          avatarUrl={repositories[3].avatarUrl}
          className="z-20 h-14 w-14"
        />
      )}
      {repositories[4]?.avatarUrl && (
        <CardMember
          src={repositories[4].url}
          avatarUrl={repositories[4].avatarUrl}
          className="z-10 h-12 w-12"
        />
      )}
      {repositories.length > 5 && (
        <div
          className="z-0 flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-gray-600 bg-cover font-bold"
          style={{
            boxShadow: "0 0 0 1px #ccc, 0 0 5px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          +{repositories.length - 5}
        </div>
      )}
    </div>
  );
}
