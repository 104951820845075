import { useEffect, useState } from "react";

const useDismissible = (key: string, showReason: string | null) => {
  const [errorDismissedMap, setErrorDismissedMap] = useState<Record<
    string,
    string | undefined
  > | null>(null);

  const dismiss = () => {
    if (showReason) {
      const errorDismissedMapCopy = { ...errorDismissedMap };
      errorDismissedMapCopy[key] = showReason;
      localStorage.setItem(
        "contactCreationErrorDismissed",
        JSON.stringify(errorDismissedMapCopy)
      );
      setErrorDismissedMap(errorDismissedMapCopy);
    }
  };

  useEffect(() => {
    // on initial load, get the dismissed map from local storage
    if (!errorDismissedMap && key && localStorage) {
      const rawErrorDismissedMap = JSON.parse(
        localStorage.getItem("contactCreationErrorDismissed") ?? "{}"
      );
      setErrorDismissedMap(rawErrorDismissedMap);
    }
  }, [key && localStorage]);
  const dismissed =
    showReason && errorDismissedMap
      ? errorDismissedMap?.[key] === showReason
      : true;
  return { dismiss, dismissed };
};

export default useDismissible;
