import { useRouter } from "next/navigation";
import { useListDeleteContact } from "~/providers/ListProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import CardHeaderDefault from "~/components/lists/MainPage/Cards/SharedCard/CardHeader";
import { TeamRenderContact } from "~/providers/ListContactProvider";
import { api } from "~/server/trpc/react";

export default function CardHeader({
  listContact,
  setShowCard,
}: Readonly<{
  listContact: NonNullable<TeamRenderContact>;
  setShowCard: () => void;
}>) {
  const { activeTeamAccount } = useTeamAccount();
  const deleteContact = useListDeleteContact();
  const { mutate: reEvaluateContact } =
    api.evaluation.reEvaluateIndividual.useMutation();

  const router = useRouter();

  const selectItem = async (name: string) => {
    if (name === "Settings") {
      router.push("/settings");
      return;
    }
    if (name === "Evaluate") {
      reEvaluateContact({
        listContactId: listContact.id,
        teamAccountId: activeTeamAccount.id,
        listId: listContact.listId as string,
        type: listContact.type,
      });
    }
    if (name === "Delete") {
      if (!listContact.githubContactId) return;
      deleteContact(
        listContact.githubContactId,
        listContact.id,
        listContact.type as "user" | "repo"
      );
      setShowCard();
    }
  };

  const contactObj = {
    id: listContact?.id,
    nameWithOwner: listContact?.githubName ?? "",
    avatarUrl: listContact.ownerAvatarUrl ?? "/hero.png",
    type: "user",
    githubName: listContact.githubName ?? "",
  };

  return (
    <CardHeaderDefault
      selectItem={selectItem}
      contactObj={contactObj}
      contactLink={`/contact/user/${listContact?.teamContactId}/commits/activity`}
    />
  );
}
