import { useList } from "~/providers/ListProvider";
import Conditions from "./Conditions";

const SecondaryInsights = ({
  userCount,
  leads,
  newLeads,
  customers,
}: {
  userCount: number;
  leads: number;
  newLeads: number;
  customers: number;
}) => {
  const list = useList();
  const today = new Date();
  const thirtyDays = 30 * 24 * 60 * 60 * 1000;
  const listEndDate = list?.endDate ?? list?.createdAt;

  const oneMonthPassed = today.getTime() - listEndDate.getTime() > thirtyDays;

  return (
    <div>
      <h3 className="py-6 text-xl font-semibold">Secondary Insights (ROI)</h3>
      {oneMonthPassed ? (
        <Conditions
          userCount={userCount}
          leads={leads}
          newLeads={newLeads}
          customers={customers}
        />
      ) : (
        <>
          Allow a one-month period to elapse following the end date of the list
          before gathering additional insights.
        </>
      )}
    </div>
  );
};
export default SecondaryInsights;
