import { type Dispatch, type SetStateAction } from "react";
import { useParams } from "next/navigation";

import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { api } from "~/server/trpc/react";
import Link from "next/link";
import Image from "next/image";
import { Dialog } from "@headlessui/react";
import { Button } from "~/components/ui/button";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Cross1Icon } from "@radix-ui/react-icons";
import { cn } from "~/lib/utils"; // Make sure to import this utility function

export default function RankList({
  setShowModal,
  showModal,
  rank,
}: Readonly<{
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  rank: string;
}>) {
  const params = useParams();
  const { listId } = params as { listId: string };

  const { activeTeamAccount } = useTeamAccount();
  const teamAccountId = activeTeamAccount?.id;

  const { data: listContacts } =
    api.listContact.getContactRanksByListId.useQuery({
      listId,
      teamAccountId,
      rank,
    });

  const nbContributors = listContacts?.length ?? 0;

  return (
    <Dialog
      open={showModal}
      onClose={() => setShowModal(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black bg-opacity-30 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white shadow-xl transition-all">
          <Card className="xl:col-span-2">
            <CardHeader className="-space-y-1 pb-2 hover:bg-gray-40 transition-colors duration-200">
              {" "}
              {/* Modified here */}
              <div className="flex items-center justify-between">
                <CardTitle>
                  {rank.charAt(0).toUpperCase() + rank.slice(1)} Contributors
                </CardTitle>
                <Button
                  size="icon"
                  variant="ghost"
                  className="text-gray-500 hover:bg-gray-200 hover:text-gray-700"
                  onClick={() => setShowModal(false)}
                >
                  <Cross1Icon className="h-4 w-4" />
                </Button>
              </div>
              <CardDescription>
                {nbContributors}{" "}
                {nbContributors === 1 ? "developer" : "developers"}{" "}
                {nbContributors === 1 ? "is" : "are"} considered to be {rank}{" "}
                {nbContributors === 1 ? "contributor" : "contributors"}.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Contributor</TableHead>
                    <TableHead className="hidden xl:table-cell">
                      GitHub Name
                    </TableHead>
                    <TableHead className="text-right">More</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {listContacts?.map((listContact) => (
                    <TableRow
                      key={listContact.id}
                      className={cn(
                        "transition-colors duration-200",
                        "hover:bg-gray-50" // Add light background on hover
                      )}
                    >
                      <TableCell>
                        <div className="flex items-center gap-3">
                          <Image
                            src={listContact.ownerAvatarUrl}
                            alt={listContact.githubName}
                            width={32}
                            height={32}
                            className="h-8 w-8 rounded-full"
                          />
                          <div className="font-medium">
                            {listContact.githubName}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="hidden xl:table-cell">
                        <div className="flex items-center gap-2">
                          <a
                            href={`https://github.com/${listContact.githubName}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-500 hover:text-gray-700"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 98 96"
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-current"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z"
                              />
                            </svg>
                          </a>
                          {listContact.githubName}
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <Button asChild size="sm">
                          <Link
                            href={`/contact/user/${listContact.teamContactId}/commits/activity`}
                            target="_blank"
                          >
                            View Profile
                          </Link>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
