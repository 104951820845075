import Link from "next/link";
import { RouterInputs } from "~/server/trpc/react";

import { pluralize } from "@openqlabs/utils";
import { useLists } from "~/providers/ListsProvider";

export type ListParamsType = RouterInputs["listContact"]["getContactsByListId"];

const NoResults = ({
  activeHeaderMenu,
  hasUsers,
  hasTeams,
  loadedWithLists,
  totalNumberOfContactsWaiting,
  loadedWithNoLists,
  searchTerm,
}: {
  activeHeaderMenu: "Teams" | "Contributors";
  hasTeams: boolean;
  hasUsers: boolean;
  loadedWithLists: boolean;
  totalNumberOfContactsWaiting: number;
  loadedWithNoLists: boolean;
  searchTerm?: string;
}) => {
  const noContacts = activeHeaderMenu === "Teams" ? !hasTeams : !hasUsers;
  const listIds = useLists().lists.map((c) => c.id) ?? [];
  const readableName = activeHeaderMenu === "Teams" ? "repository" : "user";
  return (
    <>
      {searchTerm ? (
        <>
          No {pluralize(readableName, 2)} found for the search term "
          {searchTerm}".
        </>
      ) : (
        <>
          {noContacts &&
            loadedWithLists &&
            totalNumberOfContactsWaiting !== 0 && (
              <span>
                We are adding your contacts to this list, waiting on{" "}
                {totalNumberOfContactsWaiting}{" "}
                {pluralize("contact", totalNumberOfContactsWaiting)}.
              </span>
            )}
          {noContacts &&
            loadedWithLists &&
            totalNumberOfContactsWaiting === 0 && (
              <>
                <Link
                  className="text-link hover:opacity-75 underline"
                  href={`/lists/${listIds[0] ?? ""}/add-contact`}
                >
                  Add a {pluralize(readableName, 2)} contact
                </Link>{" "}
                to populate {pluralize(readableName, 2)}. Have you just added
                one? Your contacts may not be added immediately, please reload
                the dashboard in 30 seconds.
              </>
            )}
          {noContacts && loadedWithNoLists && (
            <>
              <Link
                className="text-link hover:opacity-75 underline"
                href={`/lists/new/add-contact`}
              >
                Add a list
              </Link>{" "}
              to begin running evaluations.
            </>
          )}
        </>
      )}
    </>
  );
};

export default NoResults;
