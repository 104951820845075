import { PresentationChartLineIcon } from "@heroicons/react/24/outline";
import CardMember from "~/components/lists/MainPage/Cards/SharedCard/Member";
import { TeamRenderContact } from "~/providers/ListContactProvider";

export default function CardMembers({
  openDevTab,
  listContact,
}: Readonly<{
  openDevTab: () => void;
  listContact: TeamRenderContact;
}>) {
  const contributors =
    listContact?.contributors?.map((author) => {
      return { avatarUrl: author.avatarUrl, login: author.login };
    }) ?? [];
  const contributorsNotNull = contributors.length > 0 ? contributors : [];
  return (
    <div className="flex w-72 flex-col gap-2">
      <div className="p-t0 flex items-center justify-center -space-x-3 pt-0">
        {contributorsNotNull[0] && (
          <CardMember
            src={`https://github.com/${contributorsNotNull[0].login}`}
            avatarUrl={contributorsNotNull[0].avatarUrl}
            className="z-10 h-10 w-10"
          />
        )}
        {contributorsNotNull[1] && (
          <CardMember
            src={`https://github.com/${contributorsNotNull[1]?.login}`}
            avatarUrl={contributorsNotNull[1].avatarUrl}
            className="z-20 h-12 w-12"
          />
        )}
        {contributorsNotNull[2] && (
          <CardMember
            src={`https://github.com/${contributorsNotNull[2]?.login}`}
            avatarUrl={contributorsNotNull[2].avatarUrl}
            className="z-30 h-14 w-14"
          />
        )}
        {contributorsNotNull[3] && (
          <CardMember
            src={`https://github.com/${contributorsNotNull[3]?.login}`}
            avatarUrl={contributorsNotNull[3].avatarUrl}
            className="z-20 h-12 w-12"
          />
        )}
        {contributorsNotNull[4] && (
          <CardMember
            src={`https://github.com/${contributorsNotNull[4]?.login}`}
            avatarUrl={contributorsNotNull[4].avatarUrl}
            className="z-10 h-10 w-10"
          />
        )}
      </div>
      {contributors.length > 5 && (
        <button
          onClick={openDevTab}
          className="flex content-center items-center font-medium text-link hover:opacity-75"
        >
          +{contributors.length - 5} more
        </button>
      )}
      <div className="flex flex-1 items-end gap-2 text-lg leading-tight">
        <PresentationChartLineIcon className="h-6 w-6" />
        {listContact?.commitCount} commits in this list
      </div>
    </div>
  );
}
