import {
  ArrowPathIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import DropDown from "~/components/base/DropDown";
import { Header } from "~/components/layout/Card";
import { api } from "~/server/trpc/react";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import useSettings from "~/hooks/useSettings";
import ManualActiveModal, {
  type ContactBatchObj,
} from "~/components/base/ManualActiveModal";

interface HeaderContact {
  id: string;
  avatarUrl: string;
  nameWithOwner: string;
  type: string;
  githubName: string;
  manualStatus?: number | null;
}

export default function UserCardHeader({
  contactObj,
  contactLink,
  selectItem,
}: Readonly<{
  contactObj: HeaderContact;
  contactLink: string;
  selectItem: (name: string) => void;
}>) {
  const [modal, setModal] = useState(false);
  const isManualActive = contactObj?.manualStatus === 13;
  const { activeTeamAccount } = useTeamAccount();

  const { teamAccountUser } = useTeamAccountUser();
  const { settingItems, refetchSettingsList } = useSettings(
    [
      { name: "Evaluate", icon: ArrowPathIcon, id: "evaluate" },
      { name: "Delete", icon: TrashIcon, id: "delete" },
    ],
    contactObj.githubName,
    activeTeamAccount.id
  );
  const { avatarUrl, nameWithOwner } = contactObj;
  const [showDropdown, setShowDropdown] = useState(false);

  const [contactBatchObj, setContactBatchObj] = useState<ContactBatchObj>({
    type: contactObj?.type ?? "user",
    listId: "",
    teamAccountId: activeTeamAccount.id,
    userId: teamAccountUser?.id ?? "",
    githubUrls: [
      {
        url: contactObj.githubName,
        customFields: [],
      },
    ],
  });

  const { mutate: updateContactBatch } =
    api.contactBatchCreation.create.useMutation({
      onSuccess: async () => {
        await refetchSettingsList();
      },
    });

  const handleSelectItem = (name: string, id?: string) => {
    const newContactBatchObj = {
      ...contactBatchObj,
      listId: id as string,
    } as ContactBatchObj;
    setContactBatchObj(newContactBatchObj);
    setShowDropdown(false);
    if (name.includes("Add")) {
      if (isManualActive) {
        setModal(true);
      } else {
        updateContactBatch(newContactBatchObj);
      }
    } else selectItem(name);
  };

  const { data } = useSession();
  const accessToken = data?.accessToken;
  return (
    <Header>
      <Link href={contactLink} className="flex flex-1 items-center">
        <div className="flex items-center">
          <Image
            src={avatarUrl}
            width={24}
            height={24}
            className="mr-2 rounded-full dark:bg-white/5"
            alt="avatar"
          />
          {nameWithOwner}
        </div>
      </Link>
      <div className="flex">
        {accessToken && (
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className={"relative"}
          >
            <EllipsisVerticalIcon className="sm-icon" />

            {modal && (
              <ManualActiveModal
                setModal={setModal}
                updateContactBatch={updateContactBatch}
                contactBatchObj={contactBatchObj}
              />
            )}
            {showDropdown && (
              <DropDown
                closeDropDown={() => setShowDropdown(false)}
                className="right-0 top-6 w-60 bg-offwhite font-normal"
                items={settingItems}
                filterOpen={showDropdown}
                selectItem={handleSelectItem}
              />
            )}
          </button>
        )}
      </div>
    </Header>
  );
}
