import { useRouter } from "next/navigation";
import { useListDeleteContact } from "~/providers/ListProvider";

import { useTeamAccount } from "~/providers/TeamAccountProvider";

import CardHeaderDefault from "~/components/lists/MainPage/Cards/SharedCard/CardHeader";
import { TeamRenderContact } from "~/providers/ListContactProvider";

import { api } from "~/server/trpc/react";

export default function RepoCardHeader({
  listContact,
  setShowCard,
}: Readonly<{
  setShowCard: () => void;
  listContact: NonNullable<TeamRenderContact>;
}>) {
  const { activeTeamAccount } = useTeamAccount();
  const deleteContact = useListDeleteContact();
  const { mutate: reEvaluateContact } =
    api.evaluation.reEvaluateIndividual.useMutation();

  const router = useRouter();

  const selectItem = async (name: string) => {
    if (name === "Settings") {
      router.push("/settings");
      return;
    }
    if (name === "Evaluate") {
      reEvaluateContact({
        listContactId: listContact.id,
        teamAccountId: activeTeamAccount.id,
        listId: listContact.listId as string,

        type: listContact.type as "user" | "repo",
      });
    }
    if (name === "Delete") {
      setShowCard();
      deleteContact(
        listContact.githubContactId,
        listContact.id,
        listContact.type as "user" | "repo"
      );
    }
  };

  const contactObj = {
    id: listContact.id,
    nameWithOwner: listContact.githubName,
    avatarUrl: listContact.ownerAvatarUrl ?? "/hero.png",
    type: "repo",
    githubName: listContact.githubName,
    manualStatus: listContact.manualStatus ?? null,
  };
  return (
    <CardHeaderDefault
      selectItem={selectItem}
      contactObj={contactObj}
      contactLink={`/contact/repo/${listContact.teamContactId}/commits/activity`}
    />
  );
}
