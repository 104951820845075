import { useEffect, useRef } from "react";
import Button from "./Button";
import { RouterInputs } from "~/server/trpc/react";
import { type GithubUrl } from "@openqlabs/drm-db";

type ContactBatchInput = RouterInputs["contactBatchCreation"]["create"];

export interface ContactBatchObj {
  type: string;
  listId: string;
  teamAccountId: string;
  userId: string;
  githubUrls: { url: string; customFields: GithubUrl["customFieldValues"] }[];
  activeContacts?: boolean;
}

export default function ManualActiveModal({
  setModal,
  updateContactBatch,
  contactBatchObj,
}: Readonly<{
  setModal: (deleteModal: boolean) => void;
  updateContactBatch: (contactBatchObj: ContactBatchInput) => void;
  contactBatchObj: ContactBatchObj;
}>) {
  const modal = useRef<HTMLDivElement>(null);

  const handleStaysActive = () => {
    setModal(false);
    updateContactBatch({
      ...contactBatchObj,
      activeContacts: true,
    });
  };

  const handleNotActive = () => {
    setModal(false);
    updateContactBatch(contactBatchObj);
  };

  useEffect(() => {
    // Courtesy of https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
    function handleClickOutside(event: MouseEvent) {
      if (modal.current && !modal?.current.contains(event.target as Node)) {
        setModal(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal, setModal]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg" ref={modal}>
        <p className="text-lg font-semibold mb-4 w-[400px]">
          This contact has been manually set to be active. Do you want to
          consider it active in the list you are adding it to as well?
        </p>
        <div className="flex justify-end gap-4">
          <Button
            className="bg-gray-300 text-black  hover:bg-gray-400 w-16"
            onClick={handleNotActive}
          >
            No
          </Button>
          <Button className="w-16" onClick={handleStaysActive}>
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
}
