import Link from "next/link";
import { useState } from "react";
import Card, { Body, Summary } from "~/components/layout/Card";
import CardScores from "~/components/score/Scores";
import Stars from "~/components/lists/MainPage/Cards/SharedCard/summary/stars";

import CardHeader from "./RepoCardHeader";
import CardMembers from "./Members";
import Tabs from "./tabs/Tabs";
import useRemoveItemFromListList from "../../SharedTable/hooks/useRemoveItemFromList";
import { TeamRenderContact } from "~/providers/ListContactProvider";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export enum CardTabs {
  Changes = "changes",
  Developers = "developers",
  Dependencies = "dependencies",
}

export default function RepoCard({
  listContact,
}: Readonly<{
  listContact: TeamRenderContact;
}>) {
  const removeListListMember = useRemoveItemFromListList("repo");
  const [showTab, setShowTab] = useState<CardTabs | null>(null);
  if (!listContact) return null;

  function handleClickTab(tab: CardTabs) {
    if (showTab === tab) setShowTab(null);
    else setShowTab(tab);
  }

  const openDevTab = () => {
    handleClickTab(CardTabs.Developers);
  };

  const handleSetShowCard = () => {
    removeListListMember(listContact.id);
  };

  return (
    <Card>
      <CardHeader listContact={listContact} setShowCard={handleSetShowCard} />
      <Body>
        <Summary>
          <div>
            <div className="-mt-2 line-clamp-3">
              {listContact?.description ?? "No description available"}
            </div>
            {listContact !== null && (
              <Link
                href={`https://github.com/${listContact.githubName}`}
                target="_blank"
              >
                <>
                  Read more
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block" />
                </>{" "}
              </Link>
            )}
          </div>
          <div className="-mb-3 flex gap-4 pt-4">
            <Stars>{listContact?.totalStars ?? ""}</Stars>
            {/*BRING BACK FORKS AND ISSUE COUNT */}
          </div>
        </Summary>

        <CardMembers listContact={listContact} openDevTab={openDevTab} />

        <CardScores contactIsLoading={false} listContact={listContact} />
      </Body>
      <Tabs
        listContact={listContact}
        handleClickTab={handleClickTab}
        showTab={showTab}
      />
    </Card>
  );
}
