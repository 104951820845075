import Link from "next/link";

import Card, { Body, Summary } from "~/components/layout/Card";
import CardScores from "~/components/score/Scores";
import Followers from "~/components/lists/MainPage/Cards/SharedCard/summary/followers";
import Stars from "~/components/lists/MainPage/Cards/SharedCard/summary/stars";
import UserCardHeader from "./UserCardHeader";
import CardMembers from "./Members";
import Tabs from "./tabs/Tabs";
import useRemoveItemFromListList from "../../SharedTable/hooks/useRemoveItemFromList";
import { TeamRenderContact } from "~/providers/ListContactProvider";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export default function UserCard({
  listContact,
}: {
  listContact: NonNullable<TeamRenderContact>;
}) {
  const handleRemoveUserFromListList = useRemoveItemFromListList("user");
  const handleSetShowCard = () => {
    handleRemoveUserFromListList(listContact.id);
  };
  if (!listContact) return null;
  return (
    <Card>
      <UserCardHeader
        listContact={listContact}
        setShowCard={handleSetShowCard}
      />
      <Body>
        <Summary>
          <div>
            <div className="line-clamp-3">
              {listContact.description ?? (
                <span className="opacity-50">No bio available</span>
              )}
            </div>
            {listContact && (
              <Link
                href={`https://github.com/${listContact.githubName}`}
                className="text-link hover:opacity-75 flex gap-1 items-center"
                target="_blank"
              >
                Read more
                <ArrowTopRightOnSquareIcon className="h-3 w-3 inline-block" />
              </Link>
            )}
          </div>
          <div className="flex gap-4">
            <Stars>{listContact.totalStars}</Stars>

            <Followers>{listContact.followers}</Followers>
          </div>
        </Summary>
        <CardMembers listContact={listContact} />

        <CardScores listContact={listContact} contactIsLoading={false} />
      </Body>
      <Tabs listContact={listContact} />
    </Card>
  );
}
