import DashboardCard from "~/components/lists/MainPage/Dashboard/DashboardCard";

const RankInsights = ({
  oneTimeDevs,
  fullTimeDevs,
  partTimeDevs,
}: {
  oneTimeDevs: number;
  fullTimeDevs: number;
  partTimeDevs: number;
}) => {
  const allActiveDevs = oneTimeDevs + fullTimeDevs + partTimeDevs;

  const oneTimePercent = (
    allActiveDevs ? (oneTimeDevs / allActiveDevs) * 100 : 0
  ).toFixed(2);
  const fullTimePercent = (
    allActiveDevs ? (fullTimeDevs / allActiveDevs) * 100 : 0
  ).toFixed(2);
  const partTimePercent = (
    allActiveDevs ? (partTimeDevs / allActiveDevs) * 100 : 0
  ).toFixed(2);

  return (
    <div className="py-6">
      <div className="flex flex-wrap gap-6">
        <DashboardCard
          leadText="Full-time"
          percentage={fullTimePercent?.toString() + "%"}
          metricName="Full-time Developers"
          leads={fullTimeDevs?.toString()}
          iconType="LightningBolt"
          tooltipText="Developers who committed on at least 10 different days in the past 6 weeks"
          rank="full-time"
        />

        <DashboardCard
          leadText="Part-time"
          percentage={partTimePercent?.toString() + "%"}
          metricName="Part-time Developers"
          leads={partTimeDevs?.toString()}
          iconType="Half2"
          tooltipText="Developers who committed on more than one day in the past 6 weeks"
          rank="part-time"
        />

        <DashboardCard
          leadText="One-time"
          percentage={oneTimePercent?.toString() + "%"}
          metricName="One-time Developers"
          leads={oneTimeDevs?.toString()}
          iconType="ShadowInner"
          tooltipText="Developers who committed on a single day in the past 6 weeks"
          rank="one-time"
        />
      </div>
    </div>
  );
};
export default RankInsights;
